import type { ErrorNames } from '~types/commons'

type LocaleData = {
  message: string
  instruction: string
  icon?: string
}

const errors: Record<ErrorNames, LocaleData> = {
  INVALID_CAPTURE: {
    message: 'doc_confirmation.alert.no_doc_title',
    instruction: 'doc_confirmation.alert.no_doc_detail',
  },
  INVALID_TYPE: {
    message: 'generic.errors.invalid_type.message',
    instruction: 'generic.errors.invalid_type.instruction',
  },
  UNSUPPORTED_FILE: {
    message: 'generic.errors.unsupported_file.message',
    instruction: 'generic.errors.unsupported_file.instruction',
  },
  INVALID_SIZE: {
    message: 'generic.errors.invalid_size.message',
    instruction: 'generic.errors.invalid_size.instruction',
  },
  NO_FACE_ERROR: {
    message: 'generic.errors.no_face.message',
    instruction: 'generic.errors.no_face.instruction',
  },
  LIVENESS_FAILURE: {
    message: 'generic.errors.liveness_failure.message',
    instruction: 'generic.errors.liveness_failure.instruction',
  },
  FACE_NOT_STRAIGHT: {
    message: 'generic.errors.face_not_straight.message',
    instruction: 'generic.errors.face_not_straight.instruction',
  },
  FACE_TOO_SMALL: {
    message: 'generic.errors.face_too_small.message',
    instruction: 'generic.errors.face_too_small.instruction',
  },
  FACE_TOO_CLOSE: {
    message: 'generic.errors.face_too_close.message',
    instruction: 'generic.errors.face_too_close.instruction',
  },
  FACE_NOT_CENTRED: {
    message: 'generic.errors.face_not_centred.message',
    instruction: 'generic.errors.face_not_centred.instruction',
  },
  FACE_CROPPED: {
    message: 'generic.errors.face_cropped.message',
    instruction: 'generic.errors.face_cropped.instruction',
  },
  EYES_CLOSED: {
    message: 'generic.errors.eyes_closed.title',
    instruction: 'generic.errors.eyes_closed.detail',
  },
  FACIAL_FEATURES_OBSTRUCTED: {
    message: 'generic.errors.facial_features_obstructed.title',
    instruction: 'generic.errors.facial_features_obstructed.detail',
  },
  MULTIPLE_FACES_ERROR: {
    message: 'generic.errors.multiple_faces.message',
    instruction: 'generic.errors.multiple_faces.instruction',
  },
  REQUEST_ERROR: {
    message: 'generic.errors.request_error.message',
    instruction: 'generic.errors.request_error.instruction',
  },
  IMAGE_UNREADABLE: {
    message: 'doc_confirmation.alert.unreadable_title',
    instruction: 'doc_confirmation.alert.unreadable_detail',
  },
  CUTOFF_DETECTED: {
    message: 'doc_confirmation.alert.crop_title',
    instruction: 'doc_confirmation.alert.crop_detail',
  },
  GLARE_DETECTED: {
    message: 'doc_confirmation.alert.glare_title',
    instruction: 'doc_confirmation.alert.glare_detail',
  },
  BLUR_DETECTED: {
    message: 'doc_confirmation.alert.blur_title',
    instruction: 'doc_confirmation.alert.blur_detail',
  },
  SMS_FAILED: {
    message: 'generic.errors.sms_failed.message',
    instruction: 'generic.errors.sms_failed.instruction',
  },
  SMS_OVERUSE: {
    message: 'generic.errors.sms_overuse.message',
    instruction: 'generic.errors.sms_overuse.instruction',
  },
  CAMERA_NOT_WORKING: {
    message: 'selfie_capture.alert.camera_not_working.title',
    instruction: 'selfie_capture.alert.camera_not_working.detail',
  },
  CAMERA_NOT_WORKING_NO_FALLBACK: {
    message: 'generic.errors.camera_not_working.message',
    instruction: 'selfie_capture.alert.camera_not_working.detail_no_fallback',
  },
  CAMERA_INACTIVE: {
    message: 'selfie_capture.alert.camera_inactive.title',
    instruction: 'selfie_capture.alert.camera_inactive.detail',
  },
  CAMERA_INACTIVE_NO_FALLBACK: {
    message: 'selfie_capture.alert.camera_inactive.title',
    instruction: 'selfie_capture.alert.camera_inactive.detail_no_fallback',
  },
  LIVENESS_TIMEOUT: {
    message: 'selfie_capture.alert.timeout.title',
    instruction: 'selfie_capture.alert.timeout.detail',
  },
  GENERIC_CLIENT_ERROR: {
    message: 'cross_device_error_restart.title',
    instruction: 'cross_device_error_restart.subtitle',
  },
  FORBIDDEN_CLIENT_ERROR: {
    message: 'cross_device_error_desktop.title',
    instruction: 'cross_device_error_desktop.subtitle',
  },
  INTERRUPTED_FLOW_ERROR: {
    message: 'generic.errors.interrupted_flow_error.message',
    instruction: 'generic.errors.interrupted_flow_error.instruction',
    icon: 'flowInterruptedIcon',
  },
  UNSUPPORTED_ANDROID_BROWSER: {
    message: 'error_unsupported_browser.title_android',
    instruction: 'error_unsupported_browser.subtitle_android',
    icon: 'unsupportedBrowserIcon',
  },
  UNSUPPORTED_IOS_BROWSER: {
    message: 'error_unsupported_browser.title_ios',
    instruction: 'error_unsupported_browser.subtitle_ios',
    icon: 'unsupportedBrowserIcon',
  },
  DOCUMENT_LIVENESS_FAILED: {
    message: 'document.errors.liveness_failure.message',
    instruction: 'document.errors.liveness_failure.instruction',
  },
  DOCUMENT_PHOTOCOPY_DETECTED: {
    message: 'document.errors.photocopy_detected.message',
    instruction: 'document.errors.photocopy_detected.instruction',
  },
  DOCUMENT_TAMPERING_DETECTED: {
    message: 'document.errors.tampering_dectected.message',
    instruction: 'document.errors.tampering_dectected.instruction',
  },
}

export default errors
