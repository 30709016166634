import Webcam from 'react-webcam'
import { canvasToBlob } from './blob'

import type { Metadata } from '~types/commons'
import type { HandleCaptureProp } from '~types/routers'

export const screenshot = (
  webcam: Webcam,
  callback: (blob: Blob, metadata: Metadata) => void,
  mimeType?: string
): void => {
  if (!webcam) {
    console.error('webcam is null')
    return
  }

  const canvas = webcam && webcam.getCanvas()

  if (!canvas) {
    console.error('webcam canvas is null')
    return
  }

  const metadata = getDeviceInfo(webcam.stream)
  canvasToBlob(canvas, (blob) => callback(blob, metadata), mimeType)
}

export const getRecordedVideo = (
  webcam: Webcam,
  callback: HandleCaptureProp
): void => {
  const blob = webcam.getVideoBlob()
  const metadata = getDeviceInfo(webcam.stream)
  callback({ blob, metadata })
}

const getDeviceInfo = (stream: MediaStream): Metadata => {
  if (stream) {
    const videoTrack = stream.getVideoTracks()[0]
    const audioTrack = stream.getAudioTracks()[0]

    return {
      captureMethod: 'live',
      cameraName: videoTrack?.label,
      microphoneName: audioTrack?.label,
    }
  }

  return {}
}
