import { currentSeconds } from './index'
import type { UrlsConfig, CaptureOptions } from '~types/commons'
import type { EnterpriseFeatures } from '~types/enterprise'

type JWT = {
  exp: number
  payload: string
  uuid: string
  enterprise_features: EnterpriseFeatures
  urls: UrlsConfig
  captureOptions: CaptureOptions
}

export const parseJwt = (token?: string): JWT => {
  if (!token) {
    throw new Error('Token undefined')
  }

  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace('-', '+').replace('_', '/')
  const { urls, options, ...otherAttributes } = JSON.parse(atob(base64))
  const { api, crossDevice, sync } = urls
  const {
    hideComplyCubeLogo,
    enableCustomLogo,
    enableTextBrand,
    enableCustomCallbacks,
    documentInflightTestAttempts,
    identityCheckLivenessAttempts,
  } = options
  const parsedUrls: UrlsConfig = {
    sync_url: sync,
    complycube_api_url: api,
    hosted_sdk_url: crossDevice,
  }

  const enterprise_features: EnterpriseFeatures = {
    hideComplyCubeLogo,
    enableCustomLogo,
    enableTextBrand,
    useCustomizedApiRequests: enableCustomCallbacks,
  }

  const parsedToken = {
    ...otherAttributes,
    urls: parsedUrls,
    enterprise_features,
    captureOptions: {
      documentInflightTestAttempts,
      identityCheckLivenessAttempts,
    },
  }
  return parsedToken
}

export const jwtExpired = (token: string): boolean => {
  const { exp: expiresAt } = parseJwt(token)
  return currentSeconds() > expiresAt
}

export const getUrlsFromJWT = (token: string): UrlsConfig => {
  const urls: UrlsConfig = {}

  try {
    const jwt = parseJwt(token)
    Object.assign(urls, jwt.urls)
  } catch (err) {
    console.error('Invalid token:', err.message)
  }

  return urls
}

export const getCaptureOptionsFromJWT = (token: string): CaptureOptions => {
  const captureOptions: CaptureOptions = {}

  try {
    const jwt = parseJwt(token)
    Object.assign(captureOptions, jwt.captureOptions)
  } catch (err) {
    console.error('Invalid token:', err.message)
  }

  return captureOptions
}

export const getEnterpriseFeaturesFromJWT = (
  token: string
): EnterpriseFeatures => {
  try {
    const jwt = parseJwt(token)
    return jwt.enterprise_features
  } catch (err) {
    console.error('Invalid token:', err.message)
    return {}
  }
}
