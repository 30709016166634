/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { h, Component } from 'preact'

import MobileConnected from './MobileConnected'
import CrossDeviceSubmit from './CrossDeviceSubmit'
import MobileNotificationSent from './MobileNotificationSent'

class MobileFlow extends Component {
  componentDidMount() {
    // this.props.socket.on('disconnect ping', this.onDisconnectPing)
    this.addEventListener('disconnectPing', this.onDisconnectPing)
    this.addEventListener('requestConfig', this.sendConfig)
    this.addEventListener('clientSuccess', this.onClientSuccess)
    this.addEventListener('sendUserAnalytics', this.onUserAnalyticsEvent)
    this.addEventListener('startCrossDevice', this.onCrossBrowserStart)
    this.ping()
  }

  listeners = new Set()
  pingTimeoutId = null

  ping = () => {
    if (this.props?.socket?.readyState === 1) {
      this.sendMessage('ping')
    }
    this.pingTimeoutId = window.setTimeout(this.ping, 60000)
  }

  onDisconnectPing = () => {
    this.clearPingTimeout()
  }

  clearPingTimeout = () => {
    if (this.pingTimeoutId) {
      clearTimeout(this.pingTimeoutId)
      this.pingTimeoutId = null
    }
  }

  sendMessage = (action, payload = {}) => {
    const { roomId, socket } = this.props
    socket.send(
      JSON.stringify({
        type: 'message',
        action,
        roomId,
        ...payload,
      })
    )
  }

  addEventListener = (eventName, callback) => {
    this.listeners.add(eventName)
    this.props.socket.addEventListener('message', ({ data }) => {
      const message = JSON.parse(data)
      const { action, ...payload } = message
      if (action === eventName && this.listeners.has(eventName)) {
        payload && Object.keys(payload) ? callback(payload) : callback()
      }
    })
  }

  componentWillUnmount() {
    const { actions, clientSuccess } = this.props
    if (!clientSuccess) {
      this.sendMessage('disconnecting')
    }
    this.listeners.clear()
    actions.mobileConnected(false)
    this.clearPingTimeout()
  }

  sendConfig = (data) => {
    const { roomId, mobileConfig, actions } = this.props
    if (roomId && roomId !== data.roomId) {
      this.sendMessage('leave')
      console.log('MobileFlow:Leave')
    }
    actions.setRoomId(data.roomId)
    actions.mobileConnected(true)
    this.sendMessage('deliverConfig', { mobileConfig })
  }

  // prettier-ignore
  onClientSuccess = (data) => {
    const {
      captures,
      poaDocumentId,
      poaDocumentType,
      documentId,
      documentType,
    } = data;
    (captures || []).forEach((capture) =>
      this.props.actions.createCapture(capture)
    )
    if (poaDocumentId) {
      this.props.actions.setPoaDocumentId(poaDocumentId)
      this.props.actions.setPoaDocumentType(poaDocumentType)
    }
    if (documentId) {
      this.props.actions.setIdDocumentId(documentId)
      this.props.actions.setIdDocumentType(documentType)
    } 

    this.props.actions.setClientSuccess(true)
    this.clearPingTimeout()
  }

  onCrossBrowserStart = () => {
    dispatchEvent(
      new CustomEvent('userAnalyticsEvent', {
        detail: {
          eventName: 'CROSS_DEVICE_START',
          isCrossDevice: true,
        },
      })
    )
  }

  onUserAnalyticsEvent = (data) => {
    dispatchEvent(new CustomEvent('userAnalyticsEvent', data))
  }

  render = (props) => {
    if (this.props.clientSuccess) return <CrossDeviceSubmit {...props} />
    return this.props.mobileConnected ? (
      <MobileConnected {...props} />
    ) : (
      <MobileNotificationSent {...props} />
    )
  }
}

export default MobileFlow
