/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { performHttpReq } from './http'
import { forEach } from './object'
import { blobToBase64 } from '~utils/blob'

export const formatError = ({ response, status }, onError) => {
  try {
    onError({ status, response: JSON.parse(response) })
  } catch {
    onError({ status, response: {} })
  }
}

export const init = (url) => {
  const options = {
    endpoint: `${url}/v1/init`,
    contentType: 'application/json',
  }
  performHttpReq(
    options,
    () => console.log('init'),
    (err) => console.error(err)
  )
}

export const createDocument = (
  { type, issuingCountry },
  url,
  token,
  onSuccess,
  onError
) => {
  const data = { type, issuingCountry }
  const endpoint = `${url}/v1/documents`
  performApiRequest(endpoint, data, token, onSuccess, onError)
}

export const updateDocument = (
  { documentId, type, issuingCountry, metadata },
  url,
  token,
  onSuccess,
  onError
) => {
  const data = { type, issuingCountry, metadata }
  const endpoint = `${url}/v1/documents/${documentId}`
  performApiRequest(endpoint, data, token, onSuccess, onError)
}

export const uploadDocument = (
  { documentId, side, file, metadata, performInFlightCheck },
  url,
  token,
  onSuccess,
  onError
) => {
  const endpoint = `${url}/v1/documents/${documentId}/upload/${side}`
  const filename = file.filename || file.name || file.blob.name
  const fileName =
    filename ?? `${side}_side.${(file.type ?? file.blob.type).split('/')[1]}`

  blobToBase64(
    file.blob,
    (data) =>
      performApiRequest(
        endpoint,
        {
          fileName,
          data,
          metadata,
          performInFlightCheck: performInFlightCheck ? undefined : false,
        },
        token,
        onSuccess,
        onError
      ),
    (error) => console.error('Error converting screenshot to base64', error),
    { maxWidth: 200 }
  )
}

export const uploadLivePhoto = (
  { file, metadata, performLivenessCheck },
  url,
  token,
  onSuccess,
  onError
) => {
  const endpoint = `${url}/v1/livePhotos`
  blobToBase64(file.blob, (data) => {
    performApiRequest(
      endpoint,
      {
        data,
        metadata,
        performLivenessCheck: performLivenessCheck ? undefined : false,
      },
      token,
      onSuccess,
      onError
    )
  })
}

export const uploadSnapshot = ({ file }, url, token, onSuccess, onError) => {
  const endpoint = `${url}/v1/snapshots`
  blobToBase64(file.blob, (data) => {
    performApiRequest(
      endpoint,
      { data, fileName: file.fileName },
      token,
      onSuccess,
      onError
    )
  })
}

export const sendMultiframeSelfie = (
  snapshot,
  selfie,
  token,
  url,
  onSuccess,
  onError,
  sendEvent,
  performLivenessCheck
) => {
  const snapshotData = {
    file: {
      blob: snapshot.blob,
      filename: snapshot.filename,
    },
  }
  const { blob, filename, metadata } = selfie

  new Promise((resolve, reject) => {
    sendEvent('Starting snapshot upload')
    uploadSnapshot(snapshotData, url, token, resolve, reject)
  })
    .then((res) => {
      sendEvent('Snapshot upload completed')
      sendEvent('Starting live photo upload')
      const snapshot_uuid = res.snapshot_uuid
      uploadLivePhoto(
        {
          file: { blob, filename },
          metadata,
          snapshot_uuid,
          performLivenessCheck,
        },
        url,
        token,
        onSuccess,
        onError
      )
    })
    .catch((err) => onError(err))
}

export const uploadLiveVideo = (data, url, token, onSuccess, onError) => {
  const { challengeData, blob, metadata = {} } = data
  const { uploadLink, liveVideoId, switchSeconds } = challengeData
  uploadToSignedUrl(
    liveVideoId,
    uploadLink,
    blob,
    () => {
      const data = {
        transitionChallengesAt: [switchSeconds],
        metadata,
      }
      const endpoint = `${url}/v1/liveVideos/${liveVideoId}`
      performApiRequest(endpoint, data, token, onSuccess, onError)
    },
    onError
  )
}

export const requestChallenges = (data, token, onSuccess, onError) => {
  const { language, url } = data
  const options = {
    endpoint: `${url}/v1/liveVideos`,
    payload: {
      language,
    },
    contentType: 'application/json',
    token,
    metadata: {
      sdkSource: 'cc_web_sdk',
      sdkVersion: process.env.SDK_VERSION,
    },
  }
  performHttpReq(options, onSuccess, (request) => formatError(request, onError))
}

export const objectToFormData = (object) => {
  const formData = new FormData()
  forEach(object, (value, fieldName) => {
    if (typeof value === 'object' && value.blob && value.filename) {
      formData.append(fieldName, value.blob, value.filename)
    } else {
      formData.append(fieldName, value)
    }
  })
  return formData
}

const performApiRequest = (endpoint, data, token, onSuccess, onError) => {
  const { metadata, ...otherAttributes } = data
  const payload = {
    ...otherAttributes,
    metadata: {
      ...metadata,
      sdkSource: 'cc_web_sdk',
      sdkVersion: process.env.SDK_VERSION,
    },
  }

  const requestParams = {
    contentType: 'application/json',
    payload,
    endpoint,
    token,
  }
  performHttpReq(requestParams, onSuccess, (request) =>
    formatError(request, onError)
  )
}

const uploadToSignedUrl = (
  liveVideoId,
  signedUrl,
  data,
  onSuccess,
  onError
) => {
  const request = new XMLHttpRequest()
  request.open('PUT', signedUrl)
  request.onload = () => {
    if (request.status === 200 || request.status === 201) {
      onSuccess({ id: liveVideoId })
    } else {
      onError(request)
    }
  }
  request.onerror = () => onError(request)

  request.send(data)
}
