/* eslint-disable @typescript-eslint/no-explicit-any */
import { h, render } from 'preact'
import { getCountryCodes } from 'react-phone-number-input/modules/countries'
import labels from 'react-phone-number-input/locale/default.json'
import 'custom-event-polyfill'

// TODO: These IE11 polyfills are missing in `development` after the Typescript conversion.
//       But on PRs where the components that use these Array methods have been converted the polyfills seem to be included.
//       Should be fine to remove when those PRs are merged in eventually.
import 'array-flat-polyfill'

import { upperCase } from '~utils/string'
import { noop } from '~utils/func'
import { cssVarsPonyfill } from '~utils/cssVarsPonyfill'
import type { NormalisedSdkOptions } from '~types/commons'
import type { SdkOptions, SdkHandle } from '~types/sdk'
import type { StepConfig, StepTypes, StepConfigDocument } from '~types/steps'
import App from './components/App'

if (process.env.NODE_ENV === 'development') {
  require('preact/debug')
}

const complycubeRender = (
  options: NormalisedSdkOptions,
  el: Element | Document | ShadowRoot | DocumentFragment,
  merge?: Element | Text
) => render(<App options={options} />, el, merge)

const defaults: SdkOptions = {
  token: undefined,
  containerId: 'complycube-mount',
  useModal: true,
  isModalOpen: true,
  shouldCloseOnOverlayClick: false,
  language: 'en_US',
  onComplete: noop,
  onError: noop,
  onExit: noop,
}

const formatStep = (typeOrStep: StepConfig | StepTypes): StepConfig => {
  if (typeof typeOrStep === 'string') {
    return { type: typeOrStep }
  }

  return typeOrStep
}

const formatOptions = ({
  steps,
  smsNumberCountryCode,
  ...otherOptions
}: SdkOptions): NormalisedSdkOptions => ({
  ...otherOptions,
  smsNumberCountryCode: validateSmsCountryCode(smsNumberCountryCode),
  steps: (steps || ['welcome', 'document', 'face', 'complete']).map(formatStep),
})

const experimentalFeatureWarnings = ({ steps }: NormalisedSdkOptions) => {
  const documentStep = steps?.find(
    (step) => step.type === 'document'
  ) as StepConfigDocument

  if (!documentStep) {
    return
  }

  if (documentStep.options?.useWebcam) {
    console.warn(
      '`useWebcam` is an experimental option and is currently discouraged'
    )
  }

  if (documentStep.options?.useLiveDocumentCapture) {
    console.warn(
      '`useLiveDocumentCapture` is a beta feature and is still subject to ongoing changes'
    )
  }
}

const isSMSCountryCodeValid = (smsNumberCountryCode: string) => {
  // If you need to refactor this code, remember not to introduce large libraries such as
  // libphonenumber-js in the main bundle!
  const countries = getCountryCodes(labels)
  const isCodeValid = countries.includes(smsNumberCountryCode)
  if (!isCodeValid) {
    console.warn(
      "`smsNumberCountryCode` must be a valid two-characters ISO Country Code. 'GB' will be used instead."
    )
  }
  return isCodeValid
}

const validateSmsCountryCode = (
  smsNumberCountryCode?: string
): string | undefined => {
  if (!smsNumberCountryCode) return 'GB'
  const upperCaseCode = upperCase(smsNumberCountryCode)
  return isSMSCountryCodeValid(upperCaseCode) ? upperCaseCode : 'GB'
}

const elementIsInPage = (node: HTMLElement) =>
  node === document.body ? false : document.body.contains(node)

const getContainerElementById = (containerId: string) => {
  const el = document.getElementById(containerId)

  if (el && elementIsInPage(el)) {
    return el
  }

  throw new Error(
    `Element ID ${containerId} does not exist in current page body`
  )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const mapStageToStep = (stage: any): StepConfig => {
  const stageConfig = typeof stage === 'string' ? { name: stage } : stage
  const { name, options: stageOptions } = stageConfig
  let type = ''
  const options = {}
  switch (name) {
    case 'intro':
      type = 'welcome'
      if (stageOptions) {
        const { heading, message, startButtonText } = stageOptions
        Object.assign(options, heading ? { title: heading } : {})
        Object.assign(
          options,
          message?.length ? { descriptions: message.slice(0, 3) } : {}
        )
        Object.assign(
          options,
          startButtonText ? { nextButton: startButtonText } : {}
        )
      }
      break
    case 'userConsentCapture':
      type = 'userConsent'
      break
    case 'documentCapture':
      type = 'document'
      if (stageOptions) {
        const { crossDeviceOnly, documentTypes } = stageOptions
        Object.assign(
          options,
          crossDeviceOnly ? { forceCrossDevice: crossDeviceOnly } : {}
        )
        Object.assign(options, { documentTypes } ?? {})
        Object.assign(options, { useLiveDocumentCapture: true })
        Object.assign(options, { showCountrySelection: true })
      }
      break
    case 'faceCapture':
      type = 'face'
      if (stageOptions) {
        const { mode } = stageOptions
        Object.assign(options, {
          requestedVariant: `${mode === 'video' ? 'video' : 'standard'}`,
        })
      }
      break
    case 'poaCapture':
      type = 'poa'
      if (stageOptions) {
        const { country, documentTypes } = stageOptions
        Object.assign(options, { country } ?? {})
        if (documentTypes) {
          const { bank_statement, utility_bill } = documentTypes
          Object.assign(options, {
            documentTypes: {
              bank_statement: bank_statement ?? false,
              utility_bill: utility_bill ?? false,
            },
          })
        } else {
          Object.assign(options, {
            documentTypes: {
              bank_statement: true,
              utility_bill: true,
            },
          })
        }
      }
      break
    case 'completion':
      type = 'complete'
      if (stageOptions) {
        const { heading, message } = stageOptions
        Object.assign(options, heading ? { message: heading } : {})
        Object.assign(
          options,
          message?.length ? { submessage: message[0] } : {}
        )
      }
      break
  }
  return { type, options } as StepConfig
}

const mapAppearanceToColors = (appearance: any): any => {
  if (!appearance) return
  const map = {
    infoPopupColor: 'colorBackgroundAlertInfo',
    infoPopupTextColor: 'colorContentAlertInfo',
    infoPopupLinkHoverColor: 'colorBackgroundAlertInfoLinkHover',
    infoPopupLinkActiveColor: 'colorBackgroundAlertInfoLinkActive',
    errorPopupColor: 'colorBackgroundAlertError',
    errorPopupTextColor: 'colorContentAlertError',
    errorPopupLinkHoverColor: 'colorContentAlertErrorLinkHover',
    errorPopupLinkActiveColor: 'colorContentAlertErrorLinkActive',
    cameraButtonActiveColor: 'colorBackgroundButtonCameraActive',
    cameraButtonHoverColor: 'colorBackgroundButtonCameraHover',
    iconButtonActiveColor: 'colorBackgroundButtonIconActive',
    iconButtonHoverColor: 'colorBackgroundButtonIconHover',
    primaryButtonColor: 'colorBackgroundButtonPrimary',
    primaryButtonTextColor: 'colorContentButtonPrimaryText',
    primaryButtonActiveColor: 'colorBackgroundButtonPrimaryActive',
    primaryButtonHoverColor: 'colorBackgroundButtonPrimaryHover',
    primaryButtonBorderColor: 'colorBorderButtonPrimary',
    secondaryButtonColor: 'colorBackgroundButtonSecondary',
    secondaryButtonActiveColor: 'colorBackgroundButtonSecondaryActive',
    secondaryButtonHoverColor: 'colorBackgroundButtonSecondaryHover',
    secondaryButtonBorderColor: 'colorBorderButtonSecondary',
    secondaryButtonTextColor: 'colorContentButtonSecondaryText',
    documentSelectorTextColor: 'colorContentDocTypeButton',
    documentSelectorColor: 'colorBackgroundDocTypeButton',
    documentSelectorBorderColor: 'colorBorderDocTypeButton',
    documentSelectorActiveBorderColor: 'colorBorderDocTypeButtonActive',
    documentSelectorHoverBorderColor: 'colorBorderDocTypeButtonHover',
    linkHoverColor: 'colorBackgroundLinkHover',
    linkActiveColor: 'colorBackgroundLinkActive',
    linkUnderlineColor: 'colorBorderLinkUnderline',
    linkHoverTextColor: 'colorContentLinkTextHover',
    bodyTextColor: 'colorContentBody',
    subheadingTextColor: 'colorContentSubtitle',
    headingTextColor: 'colorContentTitle',
  }
  const customUI = {}

  Object.keys(appearance).forEach(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (key) => map[key] && (customUI[map[key]] = appearance[key])
  )
  return customUI
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
// eslint-disable-next-line @typescript-eslint/no-explicit-any
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const formatCcOptions = (opts: any): SdkOptions => {
  // if (Object.keys(opts).includes('stages')) {
  const {
    token,
    useMemoryHistory,
    stages,
    disableClientAnalytics,
    isModalOpen,
    useModal,
    branding,
    onComplete,
    mobileFlow,
    language,
    containerEl,
    containerId,
    onModalClose,
    onExit,
    onError,
    documentInflightTestAttempts,
    identityCheckLivenessAttempts,
  } = opts

  const sdkOptions: SdkOptions = {
    containerEl,
    containerId,
    token,
    isModalOpen,
    useModal,
    shouldCloseOnOverlayClick: false,
    steps: stages?.map(mapStageToStep),
    customUI: {},
    disableAnalytics: disableClientAnalytics,
    userDetails: undefined,
    useMemoryHistory,
    onComplete,
    mobileFlow,
    language,
    onModalClose,
    onExit,
    onError,
    documentInflightTestAttempts,
    identityCheckLivenessAttempts,
  }

  if (branding) {
    const { appearance, logo, textBrand } = branding
    if (appearance) {
      Object.assign(sdkOptions, {
        customUI: mapAppearanceToColors(appearance),
      })
    }
    if (textBrand && !logo) {
      Object.assign(sdkOptions, {
        enterpriseFeatures: {
          // hideComplyCubeLogo: false,
          cobrand: { text: textBrand },
        },
      })
    } else if (logo) {
      Object.assign(sdkOptions, {
        enterpriseFeatures: {
          // hideComplyCubeLogo: false,
          logoCobrand: {
            lightLogoSrc: logo?.lightLogoUrl,
            darkLogoSrc: logo?.darkLogoUrl,
          },
        },
      })
    }
  }

  Object.keys(sdkOptions).forEach(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (key) => sdkOptions[key] === undefined && delete sdkOptions[key]
  )
  return sdkOptions as SdkOptions
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const mount = (opts: any): SdkHandle => {
  console.log('complycube_sdk_version', process.env.SDK_VERSION)
  opts = formatCcOptions(opts)
  const options = formatOptions({ ...defaults, ...opts })

  // experimentalFeatureWarnings(options)
  cssVarsPonyfill()

  let containerEl: HTMLElement

  if (options.containerEl) {
    containerEl = options.containerEl
    complycubeRender(options, containerEl)
  } else if (options.containerId) {
    containerEl = getContainerElementById(options.containerId)
    complycubeRender(options, containerEl)
  }

  return {
    options,
    updateSettings(changedOptions) {
      changedOptions = formatCcOptions(changedOptions)
      this.options = formatOptions({ ...this.options, ...changedOptions })
      if (
        this.options.containerEl !== changedOptions.containerEl &&
        changedOptions.containerEl
      ) {
        containerEl = changedOptions.containerEl
      } else if (
        this.containerId !== changedOptions.containerId &&
        changedOptions.containerId
      ) {
        containerEl = getContainerElementById(changedOptions.containerId)
      }
      complycubeRender(this.options as NormalisedSdkOptions, containerEl)
      return this.options
    },
    unmount() {
      render(null, containerEl)
    },
  }
}
