import { h, Component } from 'preact'
import { trackException, sendEvent } from '../../Tracker'
import { isOfMimeType } from '~utils/blob'
import {
  uploadDocument,
  uploadLivePhoto,
  uploadLiveVideo,
  sendMultiframeSelfie,
  objectToFormData,
  formatError,
} from '~utils/ccApi'
import { poaDocumentTypes } from '../DocumentSelector/documentTypes'
import Spinner from '../Spinner'
import Previews from './Previews'

// The number of additional image quality retries
// that should return an error if an image quality validation is detected.
// This means that if image quality validations are detected, the user will only see an error
// on the first TWO upload attempt.
// From the third attempt, if image quality validations are detected, the user will see a warning
// and they use can choose to proceed regardless of the image quality warning
// const MAX_IMAGE_QUALITY_RETRIES_WITH_ERROR = 2
// const MAX_LIVENESS_CHECK_RETRIES_WITH_ERROR = 2

const IMAGE_QUALITY_KEYS_MAP = {
  invalid_capture: 'INVALID_CAPTURE',
  liveness_failure: 'LIVENESS_FAILURE',
  detect_cutoff: 'CUTOFF_DETECTED', // error with the heighest priority
  detect_glare: 'GLARE_DETECTED',
  detect_blur: 'BLUR_DETECTED',
  image_unreadable: 'IMAGE_UNREADABLE',
  document_liveness_failed: 'DOCUMENT_LIVENESS_FAILED',
  document_photocopy_detected: 'DOCUMENT_PHOTOCOPY_DETECTED',
  document_tampering_detected: 'DOCUMENT_TAMPERING_DETECTED',
}
const CALLBACK_TYPES = {
  selfie: 'onSubmitSelfie',
  video: 'onSubmitVideo',
  document: 'onSubmitDocument',
}
class Confirm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      uploadInProgress: false,
      error: {},
      performInFlightCheck: true,
      capture: null,
    }
  }

  setPerformInFlightCheck = (performInFlightCheck) => {
    this.setState({ performInFlightCheck })
  }

  setError = (name) => {
    this.setState({ error: { name, type: 'error' }, uploadInProgress: false })
    this.props.resetSdkFocus()
  }

  setWarning = (name) => {
    this.setState({ error: { name, type: 'warn' }, uploadInProgress: false })
    this.props.resetSdkFocus()
  }

  // complycubeErrorFieldMap = ([key, val]) => {
  //   if (key === 'document_detection') return 'INVALID_CAPTURE'
  //   // on corrupted PDF or other unsupported file types
  //   if (key === 'file') return 'INVALID_TYPE'
  //   // hit on PDF/invalid file type submission for face detection
  //   if (key === 'attachment' || key === 'attachment_content_type')
  //     return 'UNSUPPORTED_FILE'
  //   if (key === 'face_detection') {
  //     return val[0].indexOf('Multiple faces') === -1
  //       ? 'NO_FACE_ERROR'
  //       : 'MULTIPLE_FACES_ERROR'
  //   }
  // }

  handleImageQualityError = (errorField) => {
    for (const errorKey in IMAGE_QUALITY_KEYS_MAP) {
      if (Object.keys(errorField).includes(errorKey))
        return IMAGE_QUALITY_KEYS_MAP[errorKey]
    }
  }

  // complycubeErrorReduce = ({ fields }) => {
  //   const [first] = Object.entries(fields).map(this.ccErrorFieldMap)
  //   return first
  // }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  complycubeErrorReduce = (type) => {
    if (type === 'face_too_small') return 'FACE_TOO_SMALL'
    if (type === 'face_too_close') return 'FACE_TOO_CLOSE'
    if (type === 'face_not_centred') return 'FACE_NOT_CENTRED'
    if (type === 'face_cropped') return 'FACE_CROPPED'
    if (type === 'face_not_straight') return 'FACE_NOT_STRAIGHT'
    if (type === 'liveness_failed') return 'LIVENESS_FAILURE'
    if (type === 'eyes_closed') return 'EYES_CLOSED'
    if (type === 'facial_features_obstructed')
      return 'FACIAL_FEATURES_OBSTRUCTED'
    if (type === 'image_unreadable') return 'IMAGE_UNREADABLE'
    if (type === 'image_blurry') return 'BLUR_DETECTED'
    if (type === 'excessive_glare') return 'GLARE_DETECTED'
    if (type === 'document_type_undetected') return 'INVALID_CAPTURE'
    // on corrupted PDF or other unsupported file types
    if (type === 'file') return 'INVALID_TYPE'
    // hit on PDF/invalid file type submission for face detection
    if (type === 'invalid_image_format') return 'UNSUPPORTED_FILE'
    if (type === 'no_face_detected') return 'NO_FACE_ERROR'
    if (type === 'multiple_faces_detected') return 'MULTIPLE_FACES_ERROR'
    if (type === 'document_liveness_failed') return 'DOCUMENT_LIVENESS_FAILED'
    if (type === 'document_photocopy_detected')
      return 'DOCUMENT_PHOTOCOPY_DETECTED'
    if (type === 'document_tampering_detected')
      return 'DOCUMENT_TAMPERING_DETECTED'
    // return a generic error if the status is 422 and the key is none of the above
    return 'REQUEST_ERROR'
  }

  onApiError = (error) => {
    let errorKey
    const status = error.status || ''
    const response = error.response || {}

    if (this.props.mobileFlow && status === 401) {
      this.props.triggerOnError({ status, response })
      return this.props.crossDeviceClientError()
    } else if (status === 422) {
      errorKey = this.complycubeErrorReduce(response.type) || 'REQUEST_ERROR'
    } else {
      this.props.triggerOnError({ status, response })
      trackException(`${status} - ${response}`)
      errorKey = 'REQUEST_ERROR'
    }

    this.setError(errorKey)
  }

  imageQualityWarnings = (warnings) => {
    for (const warnKey in IMAGE_QUALITY_KEYS_MAP) {
      if (Object.keys(warnings).includes(warnKey) && !warnings[warnKey].valid)
        return IMAGE_QUALITY_KEYS_MAP[warnKey]
    }
  }

  onImageQualityWarning = (apiResponse) => {
    const { sdk_warnings: warnings } = apiResponse
    if (!warnings) {
      return null
    }
    return this.imageQualityWarnings(warnings)
  }

  onApiSuccess = (apiResponse) => {
    const { method, nextStep, actions } = this.props
    const { capture } = this.state

    const duration = Math.round(performance.now() - this.startTime)
    sendEvent('Completed upload', { duration, method })

    actions.setCaptureMetadata({ capture, apiResponse })
    actions.resetImageQualityRetries()

    const imageQualityWarning = this.onImageQualityWarning(apiResponse)

    if (!imageQualityWarning) {
      // wait a tick to ensure the action completes before progressing
      setTimeout(nextStep, 0)
    } else {
      this.setWarning(imageQualityWarning)
    }
  }

  handleSelfieUpload = ({ snapshot, ...selfie }, token) => {
    const { urls, captureOptions, imageQualityRetries } = this.props
    const url = urls.complycube_api_url
    const { identityCheckLivenessAttempts } = captureOptions

    const performLivenessCheck =
      imageQualityRetries + 1 <= identityCheckLivenessAttempts

    // if snapshot is present, it needs to be uploaded together with the user initiated selfie
    if (snapshot) {
      sendMultiframeSelfie(
        snapshot,
        selfie,
        token,
        url,
        this.onApiSuccess,
        this.onApiError,
        sendEvent,
        performLivenessCheck
      )
    } else {
      const { blob, filename, metadata } = selfie
      // filename is only present for images taken via webcam.
      // Captures that have been taken via the Uploader component do not have filename
      // and the blob is a File type
      const filePayload = filename ? { blob, filename } : blob
      uploadLivePhoto(
        { file: filePayload, metadata, performLivenessCheck },
        url,
        token,
        this.onApiSuccess,
        this.onApiError
      )
    }
  }

  getIssuingCountry = () => {
    const { idDocumentIssuingCountry, poaDocumentType, country } = this.props
    const isPoA = poaDocumentTypes.includes(poaDocumentType)
    if (isPoA) {
      return { issuingCountry: country || 'GB' }
    }
    if (idDocumentIssuingCountry && idDocumentIssuingCountry.country_alpha2) {
      return { issuingCountry: idDocumentIssuingCountry.country_alpha2 }
    }
    return {}
  }

  uploadCaptureToComplyCube = () => {
    const {
      documentId,
      poaDocumentId,
      urls,
      capture,
      // captures,
      method,
      side,
      token,
      poaDocumentType,
      language,
      imageQualityRetries,
      isDecoupledFromAPI,
      captureOptions,
    } = this.props
    const url = urls.complycube_api_url
    if (!isDecoupledFromAPI) {
      this.startTime = performance.now()
      sendEvent('Starting upload', { method })
    }
    this.setState({ uploadInProgress: true })
    const {
      blob,
      filename,
      documentType: type,
      variant,
      challengeData,
      metadata,
    } = capture
    this.setState({ capture })

    if (method === 'document' || method === 'poa') {
      const docId = method === 'document' ? documentId : poaDocumentId
      // const isPoA = poaDocumentTypes.includes(poaDocumentType)
      // const shouldPerformImageQualityValidations =
      //   !isOfMimeType(['pdf'], blob) && !isPoA
      // const shouldDetectDocument = !isPoA
      const { documentInflightTestAttempts } = captureOptions
      const performInFlightCheck =
        imageQualityRetries + 1 <= documentInflightTestAttempts
      this.setPerformInFlightCheck(performInFlightCheck)
      // const imageQualityErrorType = shouldReturnErrorForImageQuality
      //   ? 'error'
      //   : 'warn'
      // const validations = {
      //   ...(shouldDetectDocument ? { detect_document: 'error' } : {}),
      //   ...(shouldPerformImageQualityValidations
      //     ? {
      //         detect_cutoff: imageQualityErrorType,
      //         detect_glare: imageQualityErrorType,
      //         detect_blur: imageQualityErrorType,
      //         image_unreadable: imageQualityErrorType,
      //       }
      //     : {}),
      // }
      const issuingCountry = this.getIssuingCountry()
      // API does not support 'residence_permit' type but does accept 'unknown'
      // See https://documentation.complycube.com/#document-types
      const data = {
        file: { blob, filename },
        type,
        side,
        metadata,
        performInFlightCheck,
        documentId: docId,
        ...issuingCountry,
      }
      if (isDecoupledFromAPI)
        this.onSubmitCallback(data, CALLBACK_TYPES.document)
      else uploadDocument(data, url, token, this.onApiSuccess, this.onApiError)
    } else if (variant === 'video') {
      const data = { challengeData, blob, language, metadata }
      if (isDecoupledFromAPI) this.onSubmitCallback(data, CALLBACK_TYPES.video)
      else uploadLiveVideo(data, url, token, this.onApiSuccess, this.onApiError)
    } else if (isDecoupledFromAPI) {
      this.onSubmitCallback(capture, CALLBACK_TYPES.selfie)
    } else this.handleSelfieUpload(capture, token)
  }

  onSubmitCallback = async (data, callbackName) => {
    const { enterpriseFeatures, method, token, urls } = this.props
    const url = urls.complycube_api_url
    const formDataPayload = this.prepareCallbackPayload(data, callbackName)

    sendEvent(`Triggering ${callbackName} callback`)
    try {
      const {
        continueWithComplyCubeSubmission,
        complycubeSuccessResponse,
      } = await enterpriseFeatures[callbackName](formDataPayload)

      if (complycubeSuccessResponse) {
        sendEvent(`Success response from ${callbackName}`)
        this.onApiSuccess(complycubeSuccessResponse)
        return
      }

      if (continueWithComplyCubeSubmission) {
        this.startTime = performance.now()
        sendEvent('Starting upload', {
          method,
          uploadAfterNetworkDecouple: true,
        })

        if (callbackName === CALLBACK_TYPES.document) {
          uploadDocument(data, url, token, this.onApiSuccess, this.onApiError)
          return
        }

        if (callbackName === CALLBACK_TYPES.video) {
          uploadLiveVideo(data, url, token, this.onApiSuccess, this.onApiError)
          return
        }

        if (callbackName === CALLBACK_TYPES.selfie) {
          this.handleSelfieUpload(data, token)
          return
        }
      }
    } catch (errorResponse) {
      sendEvent(`Error response from ${callbackName}`)
      formatError(errorResponse, this.onApiError)
    }
  }

  prepareCallbackPayload = (data, callbackName) => {
    let payload
    if (callbackName === CALLBACK_TYPES.selfie) {
      const { blob, filename, snapshot } = data
      payload = {
        file: filename ? { blob, filename } : blob,
        snapshot,
      }
    } else if (callbackName === CALLBACK_TYPES.video) {
      const {
        blob,
        language,
        challengeData: {
          challenges: challenge,
          id: challenge_id,
          switchSeconds: challenge_switch_at,
        },
      } = data
      payload = {
        file: blob,
        challenge: JSON.stringify(challenge),
        challenge_id,
        challenge_switch_at,
        languages: JSON.stringify([{ source: 'sdk', language_code: language }]),
      }
    } else if (callbackName === CALLBACK_TYPES.document) {
      const { file, side, type, validations } = data
      payload = {
        file,
        side,
        type,
        sdk_validations: JSON.stringify(validations),
      }
    }
    return objectToFormData({
      sdk_metadata: JSON.stringify(data.metadata),
      sdk_source: 'complycube_web_sdk',
      sdk_version: process.env.SDK_VERSION,
      ...payload,
    })
  }

  onRetake = () => {
    const { actions, previousStep } = this.props

    // Retake on image quality error, increase image quality retries
    const isImageQualityError = Object.keys(IMAGE_QUALITY_KEYS_MAP).find(
      (key) => IMAGE_QUALITY_KEYS_MAP[key] === this.state.error.name
    )

    if (isImageQualityError && this.state.error.type === 'error') {
      actions.retryForImageQuality()
    }

    previousStep()
  }

  onConfirm = () => {
    if (this.state.error.type === 'warn') {
      this.props.actions.resetImageQualityRetries()
      this.props.nextStep()
    } else {
      this.uploadCaptureToComplyCube()
    }
  }

  render = ({
    capture,
    method,
    documentType,
    poaDocumentType,
    isFullScreen,
  }) => {
    const { error, uploadInProgress } = this.state

    if (uploadInProgress) {
      return <Spinner />
    }

    return (
      <Previews
        isFullScreen={isFullScreen}
        capture={capture}
        retakeAction={this.onRetake}
        confirmAction={this.onConfirm}
        isUploading={uploadInProgress}
        error={error}
        method={method}
        documentType={documentType}
        poaDocumentType={poaDocumentType}
        forceRetake={error.type === 'error'}
      />
    )
  }
}

export default Confirm
